<template>
      <div class="kefubg">
        <div class="w12">
              <div class="sftitle">联系我们</div>
              <div class="sftitlezm">Contact customer service</div>
              <div class="sftitle2">{{kefu.desc}}</div>
              <div>————</div>
              <img class="ewm"  :src="kefu.img" />
              <div class="sftitle2">{{kefu.name}}（客服微信）</div>
              <div class="sftitle2">请用微信扫码添加微信</div>
        </div>
    </div>
</template>
<style>
.kefubg{background: url('../assets/img/contactfootbg.jpg') no-repeat;background-size: 100% 100%;text-align: center;margin: auto;color: #333;padding: 60px 0;}
.kefubg .sftitle{font-size: 32px;text-align: center;}
.kefubg .sftitlezm{font-size: 14px;color: #999;padding: 5px 0 10px;}
.kefubg .sftitle2{font-size: 14px;text-align: center;padding: 5px 0;display: block;}
.kefubg .ewm{width: 162px;height: 162px;margin: 10px;}
@media only screen and (max-width:769px){
    .kefubg .sftitle{font-size: 20px;}
}
</style>
<script>
import { getWxewm } from '../request/api'
  export default {
    name: 'kefuewm',
    props: {},
    data() {
        return {
            kefu:{},
        }
    },
    mounted(){
        this.getdata();
    },
    methods: {
      getdata(){
        let that = this
        getWxewm().then(res=>{
           that.kefu = res
        })
      }
    }
  }
</script>