<template>
  <div class="help">
    <Header url="help" />

    <div class="headbg">
      <img class="light" src="../../assets/img/light.png" alt="" />
      <div class="httile">帮助中心</div>
      <div class="hsearch">
          <input type="text"  placeholder="请输入关键字" v-model="search"/>
          <div class="btnsearch" @click="gosearch()">搜索</div>
      </div>
    </div>

    <div class="hbox mobbox">
      <div class="w12 clearfix">
          <div class="menu">
                <ul><li v-for="(item, index) in cate" :key="index"  :class="menubar==index?'active':''">
                          <div @click="tapmenu(1,index,item.id)" class="dtitle line1"><span>{{item.title}} </span><i class="iconfont right">&#xe70e;</i> </div>
                          <ul>
                             <li  v-for="(item2, index2) in item['children']" :key="index2" class="line1" :class="menubar1==index2?'active2':''" @click="tapmenu(2,index2,item2.context)" >{{item2.title}}</li>
                         </ul>
                 </li></ul>
          </div>
<!-- v-show="item['id']==item2['cateid']" -->
          <div class="contentbox"  v-html="content"></div>

      </div>
    </div>
    <Msg :msg='msg' v-if="msgstatus" />
    <Wxewm />
    <Footer />
  </div>
</template>

<script>
import "../../assets/css/index.css";
import "./index.css";
import Header from "../../components/header";
import Wxewm from "../../components/kefuewm";
import Footer from "../../components/footer";
import Msg from "../../components/msg";

import { getcate,getquestion } from '../../request/api';
import marked from 'marked'
import hljs from "highlight.js";
import 'highlight.js/styles/monokai-sublime.css';
export default {
  name: "Help",
  components: { Header, Wxewm,Footer,Msg},
  data() {
    return {
      page: 0,
      limit: 10,
      cate: [],
      list: [],
      menubar:0,
      menubar1:0,
      content:'',
      search:'',
      msgstatus:false
    };
  },
  mounted() {
        this.getcatedata();


            marked.setOptions({
              renderer: new marked.Renderer(),
              highlight: function (code) {
                  return hljs.highlightAuto(code).value;
              },
              pedantic: false,
              gfm: true,
              tables: true,
              breaks: true,
              sanitize: false,
              smartLists: true,
              smartypants: false,
              xhtml: true
          })
        
  },
  methods: {
       tapmenu(t,i,context=''){
          if(t==1){
              if(this.menubar==i){this.menubar=-1}
              else{this.menubar = i;this.menubar1=-1;}       
          }else{
              this.menubar1=i;
              this.content = marked(context, {breaks: true}).replace(/<pre>/g, "<pre class='hljs'>");
          }
       },
       getcatedata(){
              let that =this;
              getcate().then(res=>{
                 if(res.code==1){
                       that.cate = res.data
                       getquestion({'all':true}).then(res2=>{
                          if(res2.code==1){
                              that.cate[0]['children'] = []
                              for(var i =0;i<res.data.length;i++){
                                    that.cate[i]['children'] = []
                                    for(var j =0;j<res2.data.length;j++){
                  
                                          if(res.data[i]['id'] == res2.data[j]['cateid']){
                                                  that.cate[i]['children'].push(res2.data[j])
                                          }
                                    }
          
                                }
                            that.content = marked(that.cate[0]['children'][0]['context'], {breaks: true}).replace(/<pre>/g, "<pre class='hljs'>");
                         }
                      })
                 }
              })
       },
       gosearch(){
               let that = this;
               if(!this.search){
                    this.msgstatus=true,this.msg='请输入关键字';
                    setTimeout(function(){that.msgstatus=false},3000);
                    return false;
               } 
               getquestion({'search':this.search}).then(res=>{
                   if(res.code==1){
                      if(res.data.length>0){
                          that.menubar=-1;
                          that.menubar1=-1;
                          that.content = marked(res.data[0].context, {breaks: true}).replace(/<pre>/g, "<pre class='hljs'>");
                      }else{
                          this.msgstatus=true,this.msg='暂无内容';
                          setTimeout(function(){that.msgstatus=false},3000);
                      }
                  }
           })
       },
        
  },
};
</script>

